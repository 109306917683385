<template>
	<div>
		<div v-if="isLoading" class="loading-spin">
			<a-spin>
				<a-icon
					slot="indicator"
					type="loading"
					style="font-size: 50px;"
					spin
				/>
			</a-spin>
		</div>
		<div v-else>
			<a-row v-if="sectionsCount === 0">
				<p class="empty-list">
					Nenhuma seção cadastrada até o momento.
				</p>
			</a-row>
			<a-row v-else class="section-menu-list">
				<a-card title="Seções" style="border-radius: 5px;">
					<div slot="extra">
						<span class="extra">Total de seções:</span>
						<a-badge
							:count="sectionsCount"
							:number-style="{
								backgroundColor: '#e8e8e8',
								color: '#000',
								fontWeight: 'bold',
							}"
							style="margin: 0px 8px 5px;"
							:show-zero="true"
							:overflow-count="999"
						/>
					</div>
					<ListBoardSections />
				</a-card>
			</a-row>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ListBoardSections from './listBoardSections'

export default {
	name: 'SectionsCard',
	components: {
		ListBoardSections,
	},
	data() {
		return {
			newCategoryName: '',
			addingCategory: false,
		}
	},
	computed: {
		...mapGetters({
			isLoading: 'menu/isLoading',
			sectionsCount: 'menu/sectionsCount',
			isMerchantActive: 'merchants/isMerchantActive',
		}),
	},
	async beforeMount() {
		if (!this.isMerchantActive) {
			this.$router.push('/home')
		} else {
			await this.fetchSections()
		}
	},
	methods: {
		...mapActions({
			fetchSections: 'menu/fetchSections',
		}),
	},
}
</script>

<style lang="less">
@import url('@/config/globalStyles.less');

.ant-card-body {
	padding: 8px 0 32px 0;
}

.extra {
	font-weight: 501;
	font-size: 15px;
	color: @secondary;
}

.section-menu-list {
	padding: 10px 0;
}
</style>
