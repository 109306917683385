<template>
	<a-form>
		<a-row type="flex" justify="start" :gutter="8">
			<a-col :span="5">
				<a-form-item label="Código" :auto-focus="true">
					<div class="size-span-wrapper">
						<a-row type="flex" justify="start" :gutter="4">
							<a-select
								v-model="product_external_code"
								v-mask="'#####'"
								show-search
								:default-active-first-option="false"
								:show-arrow="false"
								:filter-option="false"
								:auto-focus="true"
								:not-found-content="null"
								:disabled="action === 'editing'"
								class="code"
								@search="
									(str) => handleTyping(str, 'external_code')
								"
								@change="
									(value) =>
										handleSelectProduct(
											value,
											'external_code'
										)
								"
							>
								<a-select-option
									v-for="product in searchedByCode"
									:key="product.id"
									:value="product.external_code"
								>
									{{ product.external_code }}
								</a-select-option>
							</a-select>
							<a-tooltip
								v-if="action === 'editing'"
								title="Editar produto"
							>
								<a-button
									type="dashed"
									icon="edit"
									class="btn"
									@click="showEditProduct"
								/>
							</a-tooltip>
							<a-tooltip v-else title="Adicionar produto">
								<a-button
									type="dashed"
									icon="plus"
									class="btn"
									@click="showAddProduct"
								/>
							</a-tooltip>
						</a-row>
					</div>
				</a-form-item>
			</a-col>
			<a-col :span="9">
				<a-form-item label="Nome do produto">
					<div class="size-span-wrapper">
						<a-select
							v-model="name"
							show-search
							:default-active-first-option="false"
							:show-arrow="false"
							:filter-option="false"
							:not-found-content="null"
							:disabled="action === 'editing'"
							@search="(str) => handleTyping(str, 'name')"
							@change="
								(value) => handleSelectProduct(value, 'id')
							"
						>
							<a-select-option
								v-for="product in searchedByName"
								:key="product.id"
							>
								{{ product.name }}
							</a-select-option>
						</a-select>
					</div>
				</a-form-item>
			</a-col>
			<a-col :span="4">
				<a-form-item label="Preço">
					<div class="size-span-wrapper">
						<a-input
							v-model.lazy="price"
							v-money="money"
							addon-before="R$"
							@pressEnter="onSave"
						/>
					</div>
				</a-form-item>
			</a-col>
			<a-col :span="6" style="padding-top: 34px;">
				<a-form-item>
					<a-button-group>
						<a-button type="primary" @click="onSave()">
							Ok
						</a-button>
						<a-button @click="onCancel()">Cancelar</a-button>
					</a-button-group>
				</a-form-item>
			</a-col>
		</a-row>
		<div>
			<ProductDrawer
				ref="productDrawer"
				:title="productDrawerTitle"
				@save="handleSaveProduct"
				@update="handleUpdateProduct"
			/>
		</div>
	</a-form>
</template>

<script>
import { mask } from 'vue-the-mask'
import { VMoney } from 'v-money'
import { ProductDrawer } from '../../productsPage/components'
import { mapActions, mapGetters } from 'vuex'
import { currencytoNumber, toNormalize, toCurrencyFormat } from '@/utils/utils'

export default {
	name: 'SubitemForm',
	directives: { money: VMoney, mask },
	components: {
		ProductDrawer,
	},
	props: {
		subitem: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			product_id: '',
			product_available: false,
			product_external_code: '',
			name: '',
			price: '0,00',
			original_price: '0,00',
			promotional: false,
			money: {
				decimal: ',',
				thousands: '.',
				precision: 2,
				masked: false,
			},
			searchedByCode: [],
			searchedByName: [],
			productDrawerTitle: '',
			action: 'adding',
		}
	},
	computed: {
		...mapGetters({
			allProducts: 'merchants/allProducts',
		}),
	},
	async created() {
		if (this.subitem) {
			this.action = 'editing'

			const {
				name,
				price,
				product_external_code,
				product_id,
				product_available,
			} = this.subitem

			this.name = name
			this.price = toCurrencyFormat(price)
			this.product_external_code = product_external_code
			this.product_id = product_id
			this.product_available = product_available
		}

		await this.getProducts()
		this.resetSearchedProducts()
	},
	methods: {
		...mapActions('merchants', [
			'getProducts',
			'addProduct',
			'editProduct',
		]),
		async onSave() {
			if (this.name !== '') {
				if (this.action === 'editing') {
					const updated = await this.getSubitemUpdated()
					this.$emit('update', updated)
				} else {
					const price = currencytoNumber(this.price)

					this.$emit('save', {
						product_id: this.product_id,
						name: this.name,
						price: price,
						original_price: price,
						promotional: this.promotional,
						is_available: true,
						product_available: this.product_available,
						product_external_code: this.product_external_code,
					})
				}

				this.clear()
			}
		},
		onCancel() {
			this.$emit('cancel')
			this.clear()
		},
		clear() {
			this.name = ''
			this.price = '0,00'
			this.action = 'adding'
			this.product_external_code = ''
			this.resetSearchedProducts()
		},
		showAddProduct() {
			this.productDrawerTitle = 'Adicionar produto'
			this.$refs.productDrawer.setShowDrawer()
		},
		showEditProduct() {
			this.productDrawerTitle = 'Editar produto'
			let product = this.allProducts.find(
				(product) => product.id === this.product_id
			)

			if (product.external_code === null) product.external_code = ''

			this.$refs.productDrawer.setShowDrawer(product)
		},
		handleTyping(substr, property) {
			const filterString = toNormalize(substr)
			const regex = new RegExp(filterString, 'i')

			const res = this.allProducts.filter((product) =>
				toNormalize(product[property]).match(regex)
			)

			if (property === 'name') {
				this.searchedByName = res
			} else {
				this.searchedByCode = this.sortByProperty(res, 'external_code')
			}
		},
		async handleSelectProduct(value, property) {
			const product = this.allProducts.find(
				(product) => product[property] === value
			)

			this.setValuesToSubitem(product)
			this.resetSearchedProducts()
		},
		async handleSaveProduct(product) {
			try {
				const res = await this.addProduct(product)
				this.setValuesToSubitem(res)
			} finally {
				this.resetSearchedProducts()
			}
		},
		async handleUpdateProduct(edited) {
			const res = await this.editProduct(edited)
			this.setValuesToSubitem(res, false)
			this.resetSearchedProducts()
		},
		setValuesToSubitem(product, setPrice = true) {
			console.log(product)

			this.product_id = product.id
			this.product_external_code = product.external_code
			this.name = product.name
			this.product_available = product.is_available

			if (setPrice) {
				this.price = toCurrencyFormat(product.price)
				this.original_price = this.price
			}
		},
		copyValuesFromSubitem(subitem) {
			if (!subitem) return

			this.product_id = subitem.id
			this.product_external_code = subitem.product_external_code
			this.name = subitem.name
			this.price = subitem.price
			this.original_price = subitem.original_price
			this.promotional = subitem.promotional
			this.product_available = subitem.product_available
		},
		getSubitemUpdated() {
			let toUpdate = { ...this.subitem }
			toUpdate.product_id = this.product_id
			toUpdate.product_external_code = this.product_external_code
			toUpdate.price = currencytoNumber(this.price)
			toUpdate.original_price = currencytoNumber(this.original_price)
			toUpdate.promotional = this.promotional

			return toUpdate
		},
		resetSearchedProducts() {
			this.searchedByCode = this.allProducts.filter(
				(product) =>
					product.external_code && product.external_code !== ''
			)

			this.searchedByCode = this.sortByProperty(
				this.searchedByCode,
				'external_code'
			)
			this.searchedByName = this.sortByProperty(this.allProducts, 'name')
		},
		sortByProperty(array, property) {
			const func = (a, b) => {
				const va = toNormalize(a[property])
				const vb = toNormalize(b[property])

				return va > vb ? 1 : vb > va ? -1 : 0
			}

			return array.sort(func)
		},
		async showDuplicateSubitem(copy) {
			this.action = 'duplicating'

			const {
				name,
				price,
				product_external_code,
				product_id,
				product_available,
			} = copy

			this.name = name
			this.price = toCurrencyFormat(price)
			this.product_external_code = product_external_code
			this.product_id = product_id
			this.product_available = product_available

			try {
				await this.getProducts()
				this.resetSearchedProducts()
			} catch (error) {
				console.log(error)
			}
		},
	},
}
</script>

<style lang="less" scoped>
.size-span-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin: 0;

	.code {
		width: calc(100% - 44px);
		border-bottom-right-radius: 0;
		border-top-right-radius: 0;
	}

	.btn {
		margin-left: 8px;
	}
}
</style>
