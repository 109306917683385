<template>
	<a-drawer
		title="Ordenar as seções do cardápio"
		width="500px"
		:closable="false"
		:visible="showDrawer"
		@close="onClose"
	>
		<div class="drawer-body">
			<a-row type="flex">
				<p class="tip">
					Para mover a seção, clique e segure (em
					<a-icon type="pause" :rotate="90" class="handle" /> ),
					arraste para a posição desejada e solte.
				</p>
			</a-row>
			<a-row type="flex">
				<draggable
					v-model="sectionsList"
					tag="ul"
					v-bind="dragOptions"
					class="list-group"
					handle=".handle"
					@start="drag = true"
					@end="drag = false"
				>
					<transition-group
						type="transition"
						:name="!drag ? 'flip-list' : null"
					>
						<li
							v-for="section in sectionsList"
							:key="section.id"
							class="list-group-item"
						>
							<a-icon type="pause" :rotate="90" class="handle" />
							<span class="text">{{ section.name }} </span>
						</li>
					</transition-group>
				</draggable>
			</a-row>
		</div>
		<div class="drawer-footer">
			<a-button style="margin-right: 8px;" @click="onClose">
				Fechar
			</a-button>
		</div>
	</a-drawer>
</template>

<script>
import draggable from 'vuedraggable'
import { mapGetters, mapActions } from 'vuex'
import { orderByPosition } from '@/utils/utils'

export default {
	name: 'SortSectionsDrawer',
	components: {
		draggable,
	},
	data() {
		return {
			showDrawer: false,
			drag: false,
		}
	},
	computed: {
		...mapGetters({
			allSections: 'menu/allSections',
			selectedMerchantId: 'merchants/selectedMerchantId',
		}),
		sectionsList: {
			get() {
				return [...this.allSections].sort(orderByPosition)
			},
			set(values) {
				let pos = 0
				const positions = values.map((section) => {
					return {
						id: section.id,
						position: ++pos,
					}
				})

				this.sortElements({
					merchantId: this.selectedMerchantId,
					elements: 'sections',
					positions,
				})
			},
		},
		dragOptions() {
			return {
				animation: 200,
				group: 'description',
				disabled: false,
				ghostClass: 'ghost',
			}
		},
	},
	methods: {
		...mapActions('menu', ['sortElements']),
		setShowDrawer() {
			this.showDrawer = true
		},
		onClose() {
			this.showDrawer = false
		},
	},
}
</script>

<style lang="less" scoped>
.drawer-body {
	margin-bottom: 48px;

	.tip {
		padding: 12px 24px 12px 30px;
		margin: 16px 24px;
		border-left: 4px solid lightblue;
		background-color: #f8f8f8;
		position: relative;
		border-radius: 5px;
	}

	.list-group {
		margin: 24px auto;
		padding: 0;
		border: 1px solid hsv(0, 0, 85%);
		border-radius: 5px;
		width: 90%;

		.list-group-item {
			list-style-type: none;
			padding: 16px;
			border-bottom: 1px solid hsv(0, 0, 85%);

			.text {
				margin-left: 16px;
				font-size: 15px;
				font-weight: 501;
			}

			.handle {
				margin-right: 8px;
				font-size: 16px;
				font-weight: bolder;
				color: rgba(0, 0, 0, 0.35);
				cursor: grab;
				cursor: -moz-grab;
				cursor: -webkit-grab;

				&:active {
					cursor: grabbing;
					cursor: -moz-grabbing;
					cursor: -webkit-grabbing;
				}
			}
		}
	}
}
.drawer-footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	border-top: 1px solid hsv(0, 0, 85%);
	padding: 10px 16px;
	text-align: right;
	left: 0;
	background: #fff;
	border-radius: 0 0 4px 4px;
}
</style>
