<template>
	<a-row type="flex">
		<draggable
			v-if="hasItems"
			v-model="itemsList"
			tag="ul"
			v-bind="dragOptions"
			group="items"
			class="list-group"
			handle=".handle"
			@start="drag = true"
			@end="drag = false"
		>
			<transition-group
				type="transition"
				:name="!drag ? 'flip-list' : null"
			>
				<li
					v-for="item in itemsList"
					:key="item.id"
					class="list-group-item"
				>
					<a-icon type="pause" :rotate="90" class="handle" />
					<ItemElement
						:item="item"
						@switch="(value) => handleSwitch(item.id, value)"
						@edit="handleEdit(item)"
						@delete="handleDelete(item.id)"
						@duplicate="handleDuplicate(item)"
					/>
				</li>
			</transition-group>
		</draggable>
		<p v-else class="empty-list">
			Nenhum item adicionado até o momento.
		</p>
		<ItemDrawer
			ref="itemDrawer"
			:title="itemDrawerTitle"
			@save="handleSave"
			@update="handleUpdate"
		/>
	</a-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import draggable from 'vuedraggable'
import ItemElement from './itemElement'
import ItemDrawer from './itemDrawer'
import { orderByPosition } from '@/utils/utils'

export default {
	name: 'ItemsList',
	components: {
		draggable,
		ItemElement,
		ItemDrawer,
	},
	data() {
		return {
			drag: false,
			itemDrawerTitle: '',
		}
	},
	computed: {
		...mapGetters({
			sectionSelected: 'menu/sectionSelected',
			selectedMerchantId: 'merchants/selectedMerchantId',
		}),
		itemsList: {
			get: function () {
				return [...this.sectionSelected.items].sort(orderByPosition)
			},
			set(items) {
				let pos = 0
				const positions = items.map((section) => {
					return {
						id: section.id,
						position: ++pos,
					}
				})

				this.sortElements({
					merchantId: this.selectedMerchantId,
					elements: 'items',
					positions,
				})
			},
		},
		dragOptions() {
			return {
				animation: 200,
				group: 'description',
				disabled: false,
				ghostClass: 'ghost',
			}
		},
		hasItems: function () {
			return this.sectionSelected.items.length > 0
		},
	},
	methods: {
		...mapActions('menu', [
			'addItem',
			'deleteItem',
			'switchItem',
			'editItem',
			'sortElements',
		]),
		getImage(item) {
			return item.image ? item.image : 'img/placeholder-image.png'
		},
		handleSwitch(itemId, value) {
			this.switchItem({
				merchantId: this.selectedMerchantId,
				itemId,
				is_available: value,
			})
		},
		handleEdit(item) {
			this.itemDrawerTitle = 'Editar item'
			this.$refs.itemDrawer.setShowDrawer(item)
		},
		handleDuplicate(item) {
			this.itemDrawerTitle = 'Duplicar item'
			this.$refs.itemDrawer.setShowDuplicateDrawer(item)
		},
		handleDelete(itemId) {
			this.deleteItem({
				merchantId: this.selectedMerchantId,
				itemId,
			})
		},
		handleSave(newItem) {
			this.addItem({
				merchantId: this.selectedMerchantId,
				newItem,
			})
		},
		handleUpdate(values) {
			const { newItem, itemId } = values

			this.editItem({
				merchantId: this.selectedMerchantId,
				data: newItem,
				itemId,
			})
		},
		showAddItems() {
			this.itemDrawerTitle = 'Adicionar item'
			this.$refs.itemDrawer.setShowDrawer()
		},
	},
}
</script>

<style lang="less" scoped>
.empty-list {
	display: flex;
	justify-content: center;
}
.list-group {
	padding: 0;
	border-radius: 5px;
	width: 100%;

	.list-group-item {
		list-style-type: none;
		padding: 8px 8px 8px 16px;
		border-bottom: 1px solid hsv(0, 0, 85%);
		display: flex;

		.handle {
			position: relative;
			left: -14px;
			top: 23px;
			font-size: 16px;
			font-weight: bolder;
			color: rgba(0, 0, 0, 0.35);
			cursor: grab;
			cursor: -moz-grab;
			cursor: -webkit-grab;

			&:active {
				cursor: grabbing;
				cursor: -moz-grabbing;
				cursor: -webkit-grabbing;
			}
		}
	}
}

.item-container {
	list-style-type: none;

	.image {
		width: 60px;
		height: 60px;
		border-radius: 5px;
	}
	.info-container {
		height: 65px;
		padding-bottom: 8px;
		margin-bottom: 8px;
		border-bottom: 1px solid hsv(0, 0, 85%);

		.info {
			h3 {
				color: rgba(0, 0, 0, 0.7);
			}
		}
	}
	.actions {
		display: flex;
		justify-content: flex-end;
		font-size: 14px;
		padding-right: 0 !important;
		align-self: center;

		.buttons {
			font-size: 18px;
			margin: 0 8px 0 8px;
			cursor: pointer;
		}
	}

	h3 {
		font-weight: 525;
	}

	.item-description {
		font-size: 14px;
		font-weight: 525;
		color: rgba(0, 0, 0, 0.45);
		max-width: 600px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}
</style>
