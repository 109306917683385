<template>
	<a-row type="flex">
		<a-col class="section-menu-container">
			<a-row>
				<a-menu
					:default-selected-keys="current"
					mode="inline"
					theme="light"
					style="border: none;"
					@select="(payload) => onSelectSection(payload)"
				>
					<a-menu-item
						v-for="section in sectionsList"
						:key="section.position"
					>
						{{ section.name }}
					</a-menu-item>
				</a-menu>
			</a-row>
			<a-row class="sort-button" type="flex" justify="end">
				<a-button
					type="link"
					class="button"
					@click="handleSortSections"
				>
					ORDENAR SEÇÕES
				</a-button>
			</a-row>
			<a-row style="width: 100%; padding: 24px 0 8px 24px;">
				<div v-if="addingSections">
					<a-form-item label="Nome">
						<a-row>
							<a-input
								v-model="newSectionName"
								v-decorator="['sectionName']"
								style="width: 90%; min-width: 80px;"
								:auto-focus="true"
								:max-length="40"
								@keyup.enter="addNewSection"
								@keyup.esc="closeNewSectionFields"
							/>
						</a-row>
						<a-row style="width: 90%;">
							<a-tooltip title="Salvar" placement="bottom">
								<a-icon
									type="plus-circle"
									class="new-section-actions"
									@click="addNewSection"
								/>
							</a-tooltip>
							<a-tooltip title="Cancelar" placement="bottom">
								<a-icon
									type="close-circle"
									class="new-section-actions"
									@click="closeNewSectionFields"
								/>
							</a-tooltip>
						</a-row>
					</a-form-item>
				</div>
				<a-button
					v-else
					class="add-section-button"
					block
					@click="showAddSections"
				>
					<a-icon type="plus" /> Adicionar seção
				</a-button>
			</a-row>
		</a-col>
		<a-col style="width: 75%;">
			<SectionItemList />
		</a-col>
		<SortSectionsDrawer ref="sortSectionsDrawer" />
	</a-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SortSectionsDrawer from './sortSectionsDrawer'
import SectionItemList from './sectionItemList'
import { orderByPosition } from '@/utils/utils'

export default {
	name: 'ListBoardSection',
	components: {
		SectionItemList,
		SortSectionsDrawer,
	},
	data() {
		return {
			newSectionName: '',
		}
	},
	computed: {
		...mapGetters({
			allSections: 'menu/allSections',
			sectionSelected: 'menu/sectionSelected',
			addingSections: 'menu/addingSections',
			sectionsCount: 'menu/sectionsCount',
			selectedMerchantId: 'merchants/selectedMerchantId',
		}),
		current: function () {
			return [this.sectionSelected.position]
		},
		sectionsList: function () {
			return [...this.allSections].sort(orderByPosition)
		},
	},
	methods: {
		...mapActions('menu', [
			'addSection',
			'setCurrentSection',
			'setAddingSections',
		]),
		showAddSections() {
			this.setAddingSections(true)
		},
		async addNewSection() {
			if (this.newSectionName !== '') {
				const newSection = {
					name: this.newSectionName,
					position: this.sectionsCount + 1,
					is_visible: false,
				}

				await this.addSection({
					merchantId: this.selectedMerchantId,
					newSection,
				})
				this.newSectionName = ''
			}
		},
		closeNewSectionFields() {
			this.setAddingSections(false)
			this.newSectionName = ''
		},
		onSelectSection(payload) {
			this.setCurrentSection(payload.key)
		},
		handleSortSections() {
			this.$refs.sortSectionsDrawer.setShowDrawer()
		},
	},
}
</script>

<style lang="less" scoped>
@import url('@/config/globalStyles.less');

.section-menu-container {
	width: 25%;
	border-right: 1px solid @light-gray;

	.add-section-button {
		width: 90%;
		margin: 16px auto;
	}

	h2 {
		font-weight: 525;
	}

	.sort-button {
		border-top: 1px solid hsv(0, 0, 85%);
		width: 87%;
		margin: 8px 0 0 16px;

		.button {
			font-size: 11px;
			font-weight: normal;
			padding: 8px;
			color: rgba(0, 0, 0, 0.55);
		}
	}
}

.new-section-actions {
	margin-left: 4px;
	font-size: 18px;
	color: #999;
	transition: all 0.3s;
	cursor: pointer;
	float: right;

	:hover {
		color: #777;
	}
}
</style>
