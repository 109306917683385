<template>
	<div class="container">
		<a-row type="flex" class="header">
			<a-col :span="19">
				<span class="name">
					{{ subsection.name }}
					<a-tag
						v-if="subsection.min > 0"
						color="#696464"
						style="margin-top: -2px; padding: 0 2px; float: right;"
					>
						Obrigatório
					</a-tag>
				</span>
				<span class="info">
					<a-tag>
						{{
							subsection.type === 'RDO'
								? 'Selecionável'
								: 'Contável'
						}}
					</a-tag>
				</span>
				<span class="info">
					<span>Mín:</span>
					<a-badge
						:count="subsection.min"
						:number-style="numberStyle"
						style="margin: 0px 8px 5px;"
						:show-zero="true"
						:overflow-count="999"
					/>
				</span>
				<span class="info">
					<span>Máx:</span>
					<a-badge
						:count="subsection.max"
						:number-style="numberStyle"
						style="margin: 0px 8px 5px;"
						:show-zero="true"
						:overflow-count="999"
					/>
				</span>
				<span v-if="subsection.max > 1" class="info">
					Método de cobrança:
					<a-tag color="#87d068">
						{{ billingMethod }}
					</a-tag>
				</span>
			</a-col>
			<a-col :span="5">
				<ActionsButtons
					:is-available="subsection.is_visible"
					description="subseção"
					style="padding-top: 3px;"
					@switch="handleSwitch"
					@edit="handleEdit"
					@delete="handleDelete"
					@duplicate="handleDuplicate"
				/>
			</a-col>
		</a-row>
		<SubitemList
			ref="subitemList"
			:subitems="subitemsList"
			@save="handleSaveSubitem"
			@switch="handleSwitchSubitem"
			@edit="handleEditSubitem"
			@remove="handleRemoveSubitem"
			@sort="(subitems) => $emit('sort-subitems', subitems)"
		/>
	</div>
</template>

<script>
import { ActionsButtons } from '@/modules/partner/components'
import SubitemList from './subitemList'
import { mapGetters, mapActions } from 'vuex'

const billingMethods = Object.freeze({
	SUM: 'Soma',
	BGT: 'Maior',
	AVG: 'Média',
})

export default {
	name: 'Subsection',
	components: {
		ActionsButtons,
		SubitemList,
	},
	props: {
		subsection: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			numberStyle: {
				backgroundColor: '#fff',
				color: '#3574b6',
				fontWeight: 'bold',
				borderColor: '#cecece',
			},
		}
	},
	computed: {
		...mapGetters({
			selectedMerchantId: 'merchants/selectedMerchantId',
		}),
		billingMethod: function () {
			return billingMethods[this.subsection.billing_method]
		},
		subitemsList: {
			get: function () {
				return [...this.subsection.subitems]
			},
		},
	},
	methods: {
		...mapActions('menu', ['sortElements']),
		handleSwitch(value) {
			this.$emit('switch', value)
		},
		handleEdit() {
			this.$emit('edit')
		},
		handleDelete() {
			this.$emit('delete')
		},
		handleDuplicate() {
			this.$emit('duplicate')
		},
		handleSaveSubitem(subitem) {
			this.$emit('save-subitem', subitem)
		},
		handleSwitchSubitem(values) {
			this.$emit('switch-subitem', values)
		},
		handleRemoveSubitem(index) {
			this.$emit('remove-subitem', index)
		},
		handleEditSubitem(values) {
			this.$emit('edit-subitem', values)
		},
	},
}
</script>

<style lang="less" scoped>
.container {
	margin-bottom: 16px;
	background-color: #fdfdfd;
	width: 100%;

	.header {
		background-color: #efefef;
		padding: 6px 4px 4px 8px;
		border-radius: 3px;

		.name {
			font-weight: 501;
			display: block;
			color: rgba(0, 0, 0, 0.85);
			padding: 4px 2px;
		}

		.info {
			margin-right: 8px;
			color: rgba(0, 0, 0, 0.55);
		}
	}
}
</style>
