<template>
	<a-row type="flex" class="container">
		<a-row type="flex" justify="space-between" class="header">
			<a-col>
				<a-avatar
					shape="square"
					:size="60"
					icon="picture"
					:src="item.image"
					@click="handleHoverClick"
				/>
			</a-col>
			<a-col class="item-content">
				<a-row type="flex">
					<a-col
						:span="13"
						class="info-container"
						@click="handleHoverClick"
					>
						<span class="name">
							{{ getInfoHeader(item) }}
						</span>
						<span class="description">
							{{ item.description }}
						</span>
					</a-col>
					<a-col
						:span="5"
						class="info-container"
						@click="handleHoverClick"
					>
						<MoneyValue
							:value="item.price"
							:extra="additionalPrice"
						/>
					</a-col>
					<a-col :span="6" class="actions-container">
						<ActionsButtons
							:is-available="isAvailable"
							:slide-disabled="!item.product_available"
							@switch="handleSwitch"
							@edit="handleEdit"
							@delete="handleDelete"
							@duplicate="handleDuplicate"
						/>
					</a-col>
				</a-row>
			</a-col>
		</a-row>
		<a-divider
			v-if="collapsed"
			dashed
			style="color: #a8a8a8; font-size: 11px; margin: 8px 0px;"
		>
			Subseções
		</a-divider>
		<a-row v-if="collapsed" type="flex" class="body">
			<a-row
				v-if="isLoadingSubsections"
				type="flex"
				justify="center"
				style="width: 100%;"
			>
				<a-spin>
					<a-icon
						slot="indicator"
						type="loading"
						style="font-size: 25px;"
						spin
					/>
				</a-spin>
			</a-row>
			<draggable
				v-else
				v-model="subsectionsList"
				tag="ul"
				v-bind="dragOptions"
				:group="{ name: `subsections${item.position}` }"
				class="list-group"
				handle=".handle"
				@start="drag = true"
				@end="drag = false"
			>
				<transition-group
					type="transition"
					:name="!drag ? 'flip-list' : null"
				>
					<li
						v-for="subsection in subsectionsList"
						:key="subsection.id"
						class="list-group-item"
					>
						<a-icon type="pause" :rotate="90" class="handle" />
						<Subsection
							:subsection="subsection"
							@switch="
								(value) =>
									handleSwitchSubsection(value, subsection.id)
							"
							@edit="showEditSubsection(subsection)"
							@delete="
								() => handleDeleteSubsection(subsection.id)
							"
							@duplicate="showDuplicateSubsection(subsection)"
							@save-subitem="
								(subitem) =>
									handleSaveSubitem(subitem, subsection)
							"
							@switch-subitem="
								(values) =>
									handleSwitchSubitem(values, subsection)
							"
							@remove-subitem="
								(values) =>
									handleRemoveSubitem(values, subsection)
							"
							@edit-subitem="
								(values) =>
									handleUpdateSubitem(values, subsection)
							"
							@sort-subitems="
								(subitems) =>
									handleSortSubitems(subitems, subsection)
							"
						/>
					</li>
				</transition-group>
			</draggable>
		</a-row>
		<a-row
			v-if="collapsed"
			type="flex"
			style="width: 100%; margin: 16px 0; padding-left: 16px;"
		>
			<a-button style="color" block @click="showAddSubsection">
				<a-icon type="plus" />
				Adicionar subseção
			</a-button>
		</a-row>
		<SubsectionDrawer
			ref="subsectionDrawer"
			:title="subsectionDrawerTitle"
			@save="handleSaveSubsection"
			@update="handleUpdateSubsection"
		/>
	</a-row>
</template>

<script>
import { MoneyValue, ActionsButtons } from '@/modules/partner/components'
import Subsection from './subsection'
import SubsectionDrawer from './subsectionDrawer'
import draggable from 'vuedraggable'
import { mapActions, mapGetters } from 'vuex'
import { orderByPosition } from '@/utils/utils'

const radioValue = (subsection) => {
	const { subitems, billing_method, min } = subsection
	const elements = subitems
		.map((subitem) => subitem.price)
		.sort((a, b) => a - b)
		.slice(0, min)

	if (billing_method === 'BGT') {
		return Math.max.apply(Math, elements)
	} else {
		const sum = elements.reduce((sum, curr) => sum + curr, 0)

		return billing_method === 'AVG' ? sum / min : sum
	}
}

const numValue = (subsection) => {
	const { subitems, billing_method, min } = subsection
	const smaller = Math.min.apply(
		Math,
		subitems.map((subitem) => subitem.price)
	)

	return smaller * (billing_method !== 'SUM' ? 1 : min)
}

export default {
	name: 'ItemElement',
	components: {
		MoneyValue,
		ActionsButtons,
		Subsection,
		draggable,
		SubsectionDrawer,
	},
	props: {
		item: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			collapsed: false,
			drag: false,
			subsectionDrawerTitle: '',
			radioValue,
			numValue,
			loading: false,
		}
	},
	computed: {
		...mapGetters({
			selectedMerchantId: 'merchants/selectedMerchantId',
			isLoadingSubsections: 'menu/isLoadingSubsections',
		}),
		hasSubsections: function () {
			return this.item.subsections.length > 0
		},
		dragOptions() {
			return {
				animation: 200,
				group: 'description',
				disabled: false,
				ghostClass: 'ghost',
			}
		},
		isAvailable: function () {
			return this.item.product_available && this.item.is_available
		},
		subsectionsList: {
			get: function () {
				return [...(this.item.subsections || [])].sort(orderByPosition)
			},
			set: function (subsections) {
				this.setSortElements(subsections)
			},
		},
		additionalPrice: function () {
			const subsectionsRequired = this.item.subsections.filter(
				(subsection) => subsection.min > 0
			)

			const value = subsectionsRequired.reduce((sum, curr) => {
				const value =
					curr.type === 'RDO' ? radioValue(curr) : numValue(curr)

				return sum + value
			}, 0)

			return new Intl.NumberFormat('pt-BR', {
				style: 'currency',
				currency: 'BRL',
			})
				.format(value)
				.replace(/R\$/g, '')
		},
	},
	methods: {
		...mapActions('menu', [
			'addSubsection',
			'removeSubsection',
			'switchSubsection',
			'updateSubsection',
			'addSubitem',
			'removeSubitem',
			'switchSubitem',
			'updateSubitem',
			'sortElements',
		]),
		getInfoHeader() {
			return this.item.product_external_code
				? `${this.item.product_external_code} - ${this.item.name}`
				: this.item.name
		},
		handleHoverClick() {
			this.collapsed = !this.collapsed
		},
		handleSwitch(value) {
			this.$emit('switch', value)
		},
		handleDuplicate() {
			this.$emit('duplicate')
		},
		handleEdit() {
			this.$emit('edit')
		},
		handleDelete() {
			this.$emit('delete')
		},
		showAddSubsection() {
			this.subsectionDrawerTitle = 'Adicionar subseção'
			this.$refs.subsectionDrawer.setShowModal()
		},
		showEditSubsection(subsection) {
			this.subsectionDrawerTitle = 'Editar subseção'
			this.$refs.subsectionDrawer.setShowModal(subsection)
		},
		showDuplicateSubsection(subsection) {
			this.subsectionDrawerTitle = 'Duplicar subseção'
			this.$refs.subsectionDrawer.setShowDuplicateModal(subsection)
		},
		handleSaveSubsection(newSubsection) {
			try {
				newSubsection.item_id = this.item.id
				newSubsection.position = this.item.subsections.length + 1

				const payload = {
					merchantId: this.selectedMerchantId,
					newSubsection,
				}

				this.addSubsection(payload)
			} catch (error) {
				console.log(error)
			}
		},
		async handleUpdateSubsection(values) {
			try {
				const { subsection, subsectionId } = values

				const payload = {
					merchantId: this.selectedMerchantId,
					subsectionId: subsectionId,
					data: { ...subsection },
					item_id: this.item.id,
				}

				if (!subsectionId) return

				await this.updateSubsection(payload)
			} catch (error) {
				console.log(error)
			}
		},
		handleSwitchSubsection(value, subsectionId) {
			const payload = {
				merchantId: this.selectedMerchantId,
				subsectionId,
				is_visible: value,
				item_id: this.item.id,
			}
			this.switchSubsection(payload)
		},
		async handleDeleteSubsection(subsectionId) {
			try {
				const payload = {
					merchantId: this.selectedMerchantId,
					subsectionId,
					item_id: this.item.id,
				}
				await this.removeSubsection(payload)
				this.subsectionsList = this.setSortElements(
					this.item.subsections
				)
			} catch (error) {
				console.log(error)
			}
		},
		async handleSaveSubitem(subitem, subsection) {
			subitem.subsection_id = subsection.id
			subitem.position = subsection.subitems.length + 1

			const payload = {
				merchantId: this.selectedMerchantId,
				itemId: subsection.item_id,
				newSubitem: subitem,
			}

			await this.addSubitem(payload)
		},
		async handleUpdateSubitem(values, subsection) {
			const { updated, index } = values
			const subitemId = subsection.subitems[index].id

			const payload = {
				merchantId: this.selectedMerchantId,
				itemId: subsection.item_id,
				subsectionId: subsection.id,
				subitemId: subitemId,
				data: updated,
				index,
			}

			await this.updateSubitem(payload)
		},
		async handleSwitchSubitem(values, subsection) {
			const { value, index } = values
			const subitemId = subsection.subitems[index].id

			const payload = {
				merchantId: this.selectedMerchantId,
				itemId: subsection.item_id,
				subsectionId: subsection.id,
				is_available: value,
				subitemId: subitemId,
				index,
			}

			await this.switchSubitem(payload)
		},
		async handleRemoveSubitem(index, subsection) {
			const subitemId = subsection.subitems[index].id

			const payload = {
				merchantId: this.selectedMerchantId,
				itemId: subsection.item_id,
				subsectionId: subsection.id,
				subitemId: subitemId,
				index,
			}

			await this.removeSubitem(payload)
		},
		setSortElements(subsections) {
			let pos = 0
			const positions = subsections.map((subsection) => {
				subsection.position = ++pos
				return {
					id: subsection.id,
					position: pos,
				}
			})

			this.sortElements({
				merchantId: this.selectedMerchantId,
				itemId: this.item.id,
				elements: 'subsections',
				positions,
			})

			return subsections
		},
		handleSortSubitems(subitems, subsection) {
			let pos = 0
			const subsectionId = subsection.id
			const positions = subitems.map((subitem) => {
				subitem.position = ++pos
				return {
					id: subitem.id,
					position: pos,
				}
			})

			this.sortElements({
				merchantId: this.selectedMerchantId,
				itemId: this.item.id,
				elements: 'subitems',
				subsectionId,
				positions,
			})
		},
	},
}
</script>

<style lang="less" scoped>
.container {
	width: calc(100% - 1px);
	transition: all 0.3s;

	.header {
		width: 100%;

		&:hover {
			background-color: #fff7f0;
		}

		.item-content {
			width: calc(100% - 80px);

			.info-container {
				padding: 0px 8px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				height: 56px;

				.name {
					font-size: 16px;
					font-weight: 600;
					color: rgba(0, 0, 0, 0.85);

					overflow-x: hidden;
					max-width: 500px;
					text-overflow: ellipsis;
					white-space: nowrap;
				}

				.description {
					overflow-x: hidden;
					max-width: 500px;
					text-overflow: ellipsis;
					white-space: nowrap;
					font-size: 14px;
					color: rgba(0, 0, 0, 0.55);
				}

				&:hover {
					cursor: pointer;
				}
			}

			.actions-container {
				display: flex;
				flex-direction: column;
				justify-content: center;
				height: 56px;
			}
		}
	}

	.body {
		width: 100%;

		.list-group {
			padding: 0;
			border-radius: 5px;
			width: 100%;

			.list-group-item {
				list-style-type: none;
				display: flex;
				justify-content: flex-start;

				.handle {
					display: inline;
					position: relative;
					left: -4px;
					top: 26px;
					// margin: 0 8px 0 -16px;
					font-size: 16px;
					font-weight: bolder;
					color: rgba(0, 0, 0, 0.35);
					cursor: grab;
					cursor: -moz-grab;
					cursor: -webkit-grab;

					&:active {
						cursor: grabbing;
						cursor: -moz-grabbing;
						cursor: -webkit-grabbing;
					}
				}
			}
		}
	}
}
</style>
