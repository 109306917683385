<template>
	<div class="subitem-list-container" style="padding: 0;">
		<div v-if="hasSubitems()">
			<draggable
				v-model="subitemsList"
				tag="ul"
				v-bind="dragOptions"
				group="subitems"
				:sort="true"
				class="list-group"
				handle=".handle"
				@start="drag = true"
				@end="drag = false"
			>
				<transition-group
					type="transition"
					:name="!drag ? 'flip-list' : null"
				>
					<li
						v-for="(subitem, index) in subitemsList"
						:key="`${subitem.position}`"
						class="list-group-item"
					>
						<a-row
							v-if="!toEdit(index)"
							type="flex"
							justify="space-between"
							align="middle"
							class="subitem-container"
						>
							<a-col :span="14">
								<a-icon
									type="pause"
									:rotate="90"
									class="handle"
								/>
								<span v-if="subitem.product_external_code">
									{{ `${subitem.product_external_code} -` }}
								</span>
								<span>{{ subitem.name }}</span>
							</a-col>
							<a-col :span="4">
								<MoneyValue :value="subitem.price" />
							</a-col>
							<a-col :span="6" class="subitem-action-buttons">
								<ActionsButtons
									:is-available="isAvailable(subitem)"
									:slide-disabled="!subitem.product_available"
									description="subitem"
									@switch="
										(value) => handleSwitch(value, index)
									"
									@edit="handleEdit(index)"
									@delete="handleDelete(index)"
									@duplicate="handleDuplicate(subitem)"
								/>
							</a-col>
						</a-row>
						<a-row v-else-if="editing" class="edit-form-container">
							<SubitemForm
								ref="subitemEditForm"
								:subitem="subitem"
								@update="
									(updated) => handleEditSave(updated, index)
								"
								@cancel="handleEditCancel"
							/>
						</a-row>
					</li>
				</transition-group>
			</draggable>
		</div>
		<div v-else-if="!adding">
			<p class="empty-list" style="font-size: 14px; padding-top: 16px;">
				Nenhum subitem adicionado até o momento.
			</p>
		</div>
		<SubitemForm
			v-if="adding"
			ref="subitemAddForm"
			@save="handleSave"
			@cancel="handleCancel"
		/>
	</div>
</template>

<script>
import SubitemForm from './subitemForm'
import { MoneyValue, ActionsButtons } from '@/modules/partner/components'
import draggable from 'vuedraggable'
import { orderByPosition } from '@/utils/utils'

export default {
	name: 'SubitemList',
	components: {
		SubitemForm,
		MoneyValue,
		ActionsButtons,
		draggable,
	},
	props: {
		subitems: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			adding: false,
			editing: false,
			posIndex: -1,
			drag: false,
		}
	},
	computed: {
		dragOptions() {
			return {
				animation: 200,
				group: 'description',
				disabled: false,
				ghostClass: 'ghost',
			}
		},
		subitemsList: {
			get: function () {
				return [...this.subitems].sort(orderByPosition)
			},
			set: function (subitems) {
				this.$emit('sort', subitems)
			},
		},
	},
	watch: {
		adding: function () {
			this.editing = false
		},
		editing: function () {
			this.adding = false
		},
	},
	methods: {
		hasSubitems() {
			return this.subitems.length > 0
		},
		handleCancel() {
			this.adding = false
		},
		handleSave(subitem) {
			this.$emit('save', { ...subitem })
		},
		handleSwitch(value, index) {
			this.$emit('switch', { value, index })
		},
		handleEdit(index) {
			this.editing = true
			this.posIndex = index
		},
		handleDelete(index) {
			this.$emit('remove', index)
		},
		handleDuplicate(subitem) {
			this.adding = true
			setTimeout(() => {
				this.$refs.subitemAddForm.showDuplicateSubitem(subitem)
			}, 200)
		},
		setShowAdd() {
			this.adding = true
		},
		toEdit(i) {
			return this.editing && this.posIndex === i
		},
		async handleEditSave(updated, index) {
			await this.$emit('edit', { updated, index })
			this.editing = false
			this.posIndex = -1
		},
		handleEditCancel() {
			this.editing = false
			this.posIndex = -1
		},
		clearForms() {
			if (this.adding) this.$refs.subitemAddForm.onCancel()
			this.adding = false
			this.posIndex = -1
		},
		isAvailable(subitem) {
			return subitem.product_available && subitem.is_available
		},
	},
}
</script>

<style lang="less" scoped>
@import url('@/config/globalStyles.less');

.subitem-list-container {
	margin: 0;

	.list-group-item {
		padding: 0;
		list-style-type: none;

		.subitem-container {
			padding: 8px 4px 8px 4px;
			font-size: 13px;
			font-weight: 501;
			color: rgba(0, 0, 0, 0.85);
			border-bottom: 1px solid #efefef;

			&:hover {
				background-color: #fff7f0;
			}

			.handle {
				margin-right: 8px;
				font-size: 16px;
				font-weight: bolder;
				color: rgba(0, 0, 0, 0.35);
				cursor: grab;
				cursor: -moz-grab;
				cursor: -webkit-grab;

				&:active {
					cursor: grabbing;
					cursor: -moz-grabbing;
					cursor: -webkit-grabbing;
				}
			}
		}
	}

	.edit-form-container {
		border-bottom: 1px solid hsv(0, 0, 85%);
		height: auto;
		padding: 5px 0;
	}
}
</style>
