<template>
	<a-drawer
		:title="title"
		width="760px"
		:closable="false"
		:visible="showDrawer"
		@close="onClose"
	>
		<div>
			<a-tabs type="card">
				<a-tab-pane key="1" tab="Informações">
					<a-form>
						<a-row type="flex" justify="start" :gutter="36">
							<a-col :span="17">
								<a-form>
									<a-row type="flex" justify="start">
										<a-col>
											<a-form-item
												label="Código do produto"
												style="width: 160px; margin: 0;"
											>
												<div class="size-span-wrapper">
													<a-select
														v-model="
															item.product_id
														"
														v-mask="'#####'"
														show-search
														:default-active-first-option="
															false
														"
														placeholder="selecione"
														:show-arrow="false"
														:filter-option="false"
														:auto-focus="true"
														:allow-clear="
															item
																.product_external_code
																.length > 0
														"
														:not-found-content="
															null
														"
														:disabled="
															action === 'editing'
														"
														@search="(str) => handleTyping(str, 'external_code')"
														@change="(value) => handleSelectProduct(value, 'id')"
													>
														<a-select-option
															v-for="product in searchedByCode"
															:key="product.id"
														>
															{{
																product.external_code
															}}
														</a-select-option>
													</a-select>
													<span
														class="size-span"
														style="padding-top: 3px;"
													>
														{{
															item
																.product_external_code
																.length
														}}/5
													</span>
												</div>
											</a-form-item>
										</a-col>
										<a-col>
											<a-form-item
												label=""
												style="
													margin: 0;
													padding: 39px 0 0 16px;
												"
											>
												<div class="size-span-wrapper">
													<a-button
														v-if="
															action === 'editing'
														"
														@click="showEditProduct"
													>
														<a-icon type="edit" />
														Editar produto
													</a-button>
													<a-button
														v-else-if="
															action === 'adding'
														"
														@click="showAddProduct"
													>
														<a-icon type="plus" />
														Adicionar produto
													</a-button>
													<a-button
														v-else-if="
															action ===
															'duplicating'
														"
														@click="
															showDuplicateProduct
														"
													>
														<a-icon type="copy" />
														Duplicar produto
													</a-button>
												</div>
											</a-form-item>
										</a-col>
									</a-row>
									<a-form-item
										label="Nome do produto"
										style="margin: 0;"
									>
										<div class="size-span-wrapper">
											<a-select
												v-model="item.product_id"
												show-search
												:default-active-first-option="
													false
												"
												placeholder="selecione o nome do produto"
												:show-arrow="false"
												:filter-option="false"
												:not-found-content="null"
												:allow-clear="
													item.name.length > 0
												"
												:disabled="action === 'editing'"
												@search="
													(str) =>
														handleTyping(
															str,
															'name'
														)
												"
												@change="
													(value) =>
														handleSelectProduct(
															value,
															'id'
														)
												"
											>
												<a-select-option
													v-for="product in searchedByName"
													:key="product.id"
												>
													{{ product.name }}
												</a-select-option>
											</a-select>
											<span
												class="size-span"
												style="padding-top: 3px;"
											>
												{{ item.name.length }}/50
											</span>
										</div>
									</a-form-item>
									<a-form-item
										label="Descrição do produto"
										style="margin: 0;"
									>
										<div class="size-span-wrapper">
											<a-textarea
												v-model="item.description"
												:max-length="250"
												:disabled="true"
												placeholder=""
											/>
											<span class="size-span">
												{{
													item.description.length
												}}/250
											</span>
										</div>
									</a-form-item>
									<a-form-item
										label="Preço do item"
										style="margin: 0;"
									>
										<a-input
											v-money="money"
											:value="item.price"
											addon-before="R$"
											style="width: 160px;"
										/>
									</a-form-item>
									<a-form-item
										label="Tipo"
										style="margin: 0;"
									>
										<a-select v-model="item.type">
											<a-select-option
												v-for="type in types"
												:key="type.key"
											>
												{{ type.text }}
											</a-select-option>
										</a-select>
									</a-form-item>
								</a-form>
							</a-col>
							<a-col :span="7" :style="{ minHeight: '180px' }">
								<a-form-item label="Imagem">
									<img
										style="
											width: 180px;
											border-radius: 5px;
										"
										:src="preview"
									/>
								</a-form-item>
							</a-col>
						</a-row>
					</a-form>
				</a-tab-pane>
				<a-tab-pane key="2" tab="Complementos">
					<a-row type="flex">
						<h3
							style="
								width: 100%;
								margin: 16px 0;
								padding-left: 16px;
							"
						>
							Subseções
						</h3>
					</a-row>
					<a-row type="flex" class="body">
						<a-row
							v-if="loading"
							type="flex"
							justify="center"
							style="width: 100%;"
						>
							<a-spin>
								<a-icon
									slot="indicator"
									type="loading"
									style="font-size: 25px;"
									spin
								/>
							</a-spin>
						</a-row>
						<draggable
							v-else
							v-model="subsectionsList"
							tag="ul"
							v-bind="dragOptions"
							group="subsections"
							class="list-group"
							handle=".handle"
							@start="drag = true"
							@end="drag = false"
						>
							<transition-group
								type="transition"
								:name="!drag ? 'flip-list' : null"
							>
								<li
									v-for="(subsection,
									index) in subsectionsList"
									:key="subsection.position"
									class="list-group-item"
								>
									<a-icon
										type="pause"
										:rotate="90"
										class="handle"
									/>
									<Subsection
										:subsection="subsection"
										@switch="
											(value) =>
												handleSwitchSubsection(
													value,
													index
												)
										"
										@edit="showEditSubsection(subsection)"
										@delete="handleDeleteSubsection(index)"
										@duplicate="
											showDuplicateSubsection(subsection)
										"
										@save-subitem="
											(subitem) =>
												handleSaveSubitem(
													subitem,
													subsection
												)
										"
										@switch-subitem="
											(values) =>
												handleSwitchSubitem(
													values,
													subsection
												)
										"
										@remove-subitem="
											(pos) =>
												handleRemoveSubitem(
													pos,
													subsection
												)
										"
										@edit-subitem="
											(values) =>
												handleEditSubitem(
													values,
													subsection
												)
										"
										@sort-subitems="
											(subitems) =>
												handleSortSubitems(
													subitems,
													subsection
												)
										"
									/>
								</li>
							</transition-group>
						</draggable>
					</a-row>
					<a-row
						type="flex"
						style="
							width: 100%;
							margin: 24px 0px 64px 0px;
							padding-left: 16px;
						"
					>
						<a-button
							type="primary"
							block
							icon="plus"
							@click="showAddSubsection"
						>
							Adicionar subseção
						</a-button>
					</a-row>
				</a-tab-pane>
			</a-tabs>
		</div>
		<div v-if="action !== 'editing'" class="drawer-footer">
			<a-button style="margin-right: 8px;" @click="onClose">
				Cancelar
			</a-button>
			<a-button type="primary" @click="onSave">
				Salvar
			</a-button>
		</div>
		<div v-else class="drawer-footer">
			<a-button type="primary" :loading="loading" @click="onSave">
				Salvar
			</a-button>
		</div>
		<ProductDrawer
			ref="productDrawer"
			:title="productDrawerTitle"
			@save="handleSaveProduct"
			@update="handleUpdateProduct"
		/>
		<SubsectionDrawer
			ref="subsectionDrawer"
			:title="subsectionDrawerTitle"
			@save="handleSaveSubsection"
			@update="handleUpdateSubsection"
		/>
	</a-drawer>
</template>

<script>
import { VMoney } from 'v-money'
import { mask } from 'vue-the-mask'
import draggable from 'vuedraggable'
import Subsection from './subsection'
import { mapGetters, mapActions } from 'vuex'
import SubsectionDrawer from './subsectionDrawer'
import { ProductDrawer } from '../../productsPage/components'
import {
	orderByPosition,
	toCurrencyFormat,
	toNormalize,
	currencytoNumber,
} from '@/utils/utils'

const ItemInitialState = {
	product_id: null,
	product_external_code: '',
	name: '',
	description: '',
	price: '0,00',
	original_price: '0,00',
	promotional: false,
	is_available: true,
	type: 'MAIN',
	position: 0,
	subsections: [],
}

const types = [
	{ key: 'READY', text: 'Pronta entrega' },
	{ key: 'APPETIZER', text: 'Entrada' },
	{ key: 'MAIN', text: 'Prato principal' },
	{ key: 'DESSERT', text: 'Sobremesa' },
]

export default {
	name: 'ItemDrawer',
	directives: { money: VMoney, mask },
	components: {
		SubsectionDrawer,
		ProductDrawer,
		draggable,
		Subsection,
	},
	props: {
		title: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			drag: false,
			showDrawer: false,
			item: { ...ItemInitialState },
			preview: 'img/placeholder-image.png',
			action: 'adding',
			loading: false,
			imageChanged: false,
			types,
			money: {
				decimal: ',',
				thousands: '.',
				precision: 2,
				masked: false,
			},
			searchedByCode: [],
			searchedByName: [],
			productDrawerTitle: '',
			subsectionDrawerTitle: '',
		}
	},
	computed: {
		...mapGetters({
			sectionSelected: 'menu/sectionSelected',
			isLoadingSubsections: 'menu/isLoadingSubsections',
			allProducts: 'merchants/allProducts',
			selectedMerchantId: 'merchants/selectedMerchantId',
		}),
		productsFilter() {
			return this.item.external_code
		},
		dragOptions() {
			return {
				animation: 200,
				group: 'description',
				disabled: false,
				ghostClass: 'ghost',
			}
		},
		subsectionsList: {
			get: function () {
				return [...this.item.subsections].sort(orderByPosition)
			},
			set: function (subsections) {
				this.item.subsections = this.setSortElements(subsections)
			},
		},
	},
	async beforeMount() {
		await this.getProducts()
		this.resetSearchedProducts()
	},
	methods: {
		...mapActions('merchants', [
			'getProducts',
			'addProduct',
			'editProduct',
			'deleteProduct',
		]),
		...mapActions('menu', [
			'addSubsection',
			'removeSubsection',
			'switchSubsection',
			'updateSubsection',
			'addSubitem',
			'removeSubitem',
			'switchSubitem',
			'updateSubitem',
			'sortElements',
		]),
		...mapActions('alert', ['error']),
		setShowDrawer(currentState) {
			if (currentState) {
				this.action = 'editing'
				this.item = { ...currentState }
				this.item.price = toCurrencyFormat(this.item.price)

				if (this.item.image && this.item.image !== '')
					this.preview = this.item.image
			}
			this.showDrawer = true
		},
		setShowDuplicateDrawer(currentState) {
			this.action = 'duplicating'
			this.item = { ...currentState }
			this.item.price = toCurrencyFormat(this.item.price)

			this.item.id = ''
			this.item.subsections.forEach((subsection) => {
				subsection.id = ''
				subsection.subitems.forEach((subitem) => (subitem.id = ''))
			})

			if (this.item.image && this.item.image !== '')
				this.preview = this.item.image

			this.showDrawer = true
		},
		showAddProduct() {
			this.productDrawerTitle = 'Adicionar produto'
			this.$refs.productDrawer.setShowDrawer()
		},
		showEditProduct() {
			this.productDrawerTitle = 'Editar produto'
			let product = this.allProducts.find(
				(product) => product.id === this.item.product_id
			)

			if (product.external_code === null) product.external_code = ''

			this.$refs.productDrawer.setShowDrawer(product)
		},
		showDuplicateProduct() {
			this.productDrawerTitle = 'Duplicar produto'
			let product = this.allProducts.find(
				(product) => product.id === this.item.product_id
			)

			this.$refs.productDrawer.setShowDrawerDup(product)
		},
		showAddSubsection() {
			this.subsectionDrawerTitle = 'Adicionar subseção'
			this.$refs.subsectionDrawer.setShowModal()
		},
		showEditSubsection(subsection) {
			this.subsectionDrawerTitle = 'Editar subseção'
			this.$refs.subsectionDrawer.setShowModal(subsection)
		},
		showDuplicateSubsection(subsection) {
			this.subsectionDrawerTitle = 'Duplicar subseção'
			this.$refs.subsectionDrawer.setShowDuplicateModal(subsection)
		},
		onClose() {
			this.showDrawer = false
			this.action = 'adding'
			this.item = { ...ItemInitialState }

			this.$refs.subsectionDrawer.clearDrawer()
			this.resetSearchedProducts()
		},
		handleTyping(substr, property) {
			const filterString = toNormalize(substr)
			const regex = new RegExp(filterString, 'i')

			if (substr == '') {
				this.resetSearchedProducts();

				return
			}

			const res = this.allProducts.filter((product) =>
				toNormalize(product[property]).match(regex)
			)

			if (property === 'name') {
				this.searchedByName = res
			} else {
				this.searchedByCode = this.sortByProperty(res, 'external_code')
			}
		},

		async handleSelectProduct(value, property) {
			const product = this.allProducts.find(
				(product) => product[property] === value
			)

			this.setValuesToItem(product)
			this.resetSearchedProducts()
		},

		async handleSaveProduct(product) {
			try {
				this.$message.loading({
					content: 'Adicionando produto...',
					key: 'updatable',
				})

				const res = await this.addProduct(product)

				this.setValuesToItem(res)
				this.resetSearchedProducts()
			} catch (error) {
				this.$message.destroy()
				console.log(error)
			} finally {
				this.$message.success({
					content: 'Produto adicionado com sucesso',
					key: 'updatable',
					duration: 4,
				})
			}
		},
		async handleUpdateProduct(edited) {
			try {
				this.$message.loading({
					content: 'Atualizando produto...',
					key: 'updatable',
				})

				const res = await this.editProduct(edited)
				this.setValuesToItem(res, false)
				this.resetSearchedProducts()
			} catch (error) {
				this.$message.destroy()
			} finally {
				this.$message.success({
					content: 'Produto atualizado com sucesso',
					key: 'updatable',
					duration: 4,
				})
			}
		},
		onSave() {
			if (this.item.name !== '') {
				const price = currencytoNumber(this.item.price)

				const newItem = {
					product_id: this.item.product_id,
					price,
					original_price: price,
					promotional: this.item.promotional,
					type: this.item.type,
				}

				if (this.action === 'editing') {
					const itemId = this.item.id
					this.$emit('update', { newItem, itemId })
				} else {
					newItem.section_id = this.sectionSelected.id
					newItem.is_available = this.item.is_available
					newItem.position = this.sectionSelected.items.length + 1
					newItem.subsections = this.item.subsections

					this.$emit('save', newItem)
				}

				this.onClose()
			} else {
				this.$message.error('O nome do item não foi informado.')
			}
		},
		async setValuesToItem(product, setPrice = true) {
			this.item.product_id = product.id
			this.item.product_external_code = product.external_code || ''
			this.item.name = product.name
			this.item.description = product.description

			if (setPrice) {
				this.item.price = toCurrencyFormat(product.price)
				this.item.original_price = this.item.price
			}

			if (product.image && product.image !== '')
				this.preview = product.image
		},
		async handleSaveSubsection(subsection) {
			subsection.position = this.subsectionsList.length + 1

			if (this.action === 'editing' && this.item.id) {
				try {
					subsection.item_id = this.item.id
					const payload = {
						merchantId: this.selectedMerchantId,
						newSubsection: subsection,
					}

					this.loading = true
					await this.addSubsection(payload)
				} catch (error) {
					console.log(error)
				} finally {
					this.loading = false
				}
			} else {
				this.subsectionsList = [...this.item.subsections, subsection]
			}
		},
		async handleSwitchSubsection(value, index) {
			if (this.action === 'editing' && this.item.id) {
				const payload = {
					merchantId: this.selectedMerchantId,
					subsectionId: this.item.subsections[index].id,
					is_visible: value,
					item_id: this.item.id,
				}

				await this.switchSubsection(payload)
			}

			this.item.subsections[index].is_visible = value
		},
		async handleDeleteSubsection(index) {
			if (this.action === 'editing' && this.item.id) {
				const payload = {
					merchantId: this.selectedMerchantId,
					subsectionId: this.item.subsections[index].id,
					item_id: this.item.id,
				}

				this.loading = true
				await this.removeSubsection(payload)
				this.loading = false
			}

			this.item.subsections.splice(index, 1)
			const sorted = this.setSortElements(this.item.subsections)
			this.subsectionsList = sorted
		},
		async handleUpdateSubsection(values) {
			const { subsection, subsectionId } = values

			if (this.action === 'editing' && this.item.id) {
				try {
					const payload = {
						merchantId: this.selectedMerchantId,
						subsectionId: subsectionId,
						data: subsection,
						item_id: this.item.id,
					}

					this.loading = true
					const res = await this.updateSubsection(payload)

					const index = this.item.subsections.findIndex(
						(subsection) => subsection.id === subsectionId
					)
					this.item.subsections[index] = { ...res }
					this.subsectionsList = [...this.item.subsections]
				} catch (error) {
					console.log(error)
				} finally {
					this.loading = false
				}
			} else {
				const index = this.item.subsections.findIndex(
					(sub) => sub.position === subsection.position
				)

				this.item.subsections[index] = { ...subsection }
				this.subsectionsList = [...this.item.subsections]
			}
		},
		async handleSwitchSubitem(values, subsection) {
			const { value, index } = values
			const subitemId = subsection.subitems[index].id

			if (this.action === 'editing' && subitemId) {
				const payload = {
					merchantId: this.selectedMerchantId,
					itemId: subsection.item_id,
					subsectionId: subsection.id,
					is_available: value,
					subitemId: subitemId,
					index,
				}

				await this.switchSubitem(payload)
			}

			subsection.subitems[index].is_available = value
		},
		async handleSaveSubitem(subitem, subsection) {
			subitem.subsection_id = subsection.id
			subitem.position = subsection.subitems.length + 1

			if (this.action === 'editing') {
				const payload = {
					merchantId: this.selectedMerchantId,
					itemId: subsection.item_id,
					newSubitem: subitem,
				}

				await this.addSubitem(payload)
			} else {
				subsection.subitems.push(subitem)
			}
		},
		async handleRemoveSubitem(index, subsection) {
			const subitemId = subsection.subitems[index].id

			if (this.action === 'editing' && subitemId) {
				try {
					const payload = {
						merchantId: this.selectedMerchantId,
						itemId: subsection.item_id,
						subsectionId: subsection.id,
						subitemId: subitemId,
					}

					this.loading = true
					await this.removeSubitem(payload)
				} catch (error) {
					console.log(error)
				} finally {
					this.loading = false
				}
			} else {
				subsection.subitems.splice(index, 1)
			}

			this.handleSortSubitems(subsection.subitems, subsection)
		},
		async handleEditSubitem(values, subsection) {
			const { updated, index } = values

			const subitemId = subsection.subitems[index].id

			if (this.action === 'editing' && subitemId) {
				try {
					const payload = {
						merchantId: this.selectedMerchantId,
						itemId: subsection.item_id,
						subsectionId: subsection.id,
						subitemId: subitemId,
						data: updated,
						index,
					}

					this.loading = true

					const res = await this.updateSubitem(payload)
					const subsecIndex = this.item.subsections.findIndex(
						(el) => el.id === res.subsection_id
					)
					for (const key in res)
						this.item.subsections[subsecIndex].subitems[index][
							key
						] = res[key]
					this.subsectionsList = this.item.subsections
				} catch (error) {
					console.log(error)
				} finally {
					this.loading = false
				}
			} else {
				const subsecIndex = this.item.subsections.findIndex(
					(el) => el.position === subsection.position
				)
				for (const key in updated)
					this.item.subsections[subsecIndex].subitems[index][key] =
						updated[key]
			}
		},
		handleSortSubitems(subitems, subsection) {
			let pos = 0
			const subsectionId = subsection.id
			const positions = subitems.map((subitem) => {
				subitem.position = ++pos
				return {
					id: subitem.id,
					position: pos,
				}
			})

			if (this.action === 'editing' && subsectionId) {
				this.sortElements({
					merchantId: this.selectedMerchantId,
					itemId: this.item.id,
					elements: 'subitems',
					subsectionId,
					positions,
				})
			}

			const subsecIndex = this.item.subsections.findIndex(
				(el) => el.id === subsectionId
			)
			this.item.subsections[subsecIndex].subitems = [...subitems]
			this.subsectionsList = this.item.subsections
		},
		resetSearchedProducts() {
			this.searchedByCode = this.allProducts.filter(
				(product) =>
					product.external_code && product.external_code !== ''
			)

			this.searchedByCode = this.sortByProperty(
				this.searchedByCode,
				'external_code'
			)
			this.searchedByName = this.sortByProperty(this.allProducts, 'name')
		},
		setSortElements(subsections) {
			if (this.action === 'editing') {
				let pos = 0
				const positions = subsections.map((subsection) => {
					subsection.position = ++pos
					return {
						id: subsection.id,
						position: pos,
					}
				})

				this.sortElements({
					merchantId: this.selectedMerchantId,
					itemId: this.item.id,
					elements: 'subsections',
					positions,
				})
			}

			return subsections
		},
		sortByProperty(array, property) {
			const func = (a, b) => {
				const va = toNormalize(a[property])
				const vb = toNormalize(b[property])

				return va > vb ? 1 : vb > va ? -1 : 0
			}

			return array.sort(func)
		},
	},
	priceChange(value) {
		console.log(value)
	},
}
</script>

<style lang="less" scoped>
.drawer-footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	border-top: 1px solid hsv(0, 0, 85%);
	padding: 10px 16px;
	text-align: right;
	left: 0;
	background: #fff;
	border-radius: 0 0 4px 4px;
}
.avatar-upload-wrapper {
	height: 200px;
	width: 100%;
}
.ant-upload-preview {
	position: relative;
	margin: 0 auto;
	width: 100%;
	max-width: 240px;

	.mask {
		opacity: 0;
		position: absolute;
		background: rgba(0, 0, 0, 0.4);
		cursor: pointer;
		transition: opacity 0.4s;
		&:hover {
			opacity: 1;
		}
		i {
			font-size: 2rem;
			position: absolute;
			top: 50%;
			left: 50%;
			margin-left: -1rem;
			margin-top: -1rem;
			color: #d6d6d6;
		}
	}
	img,
	.mask {
		width: 100%;
		max-width: 200px;
		height: 100%;
		border-radius: 5px;
		overflow: hidden;
	}
}
.size-span-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin: 0;

	.size-span {
		color: rgba(0, 0, 0, 0.45);
		font-size: 12px;
		line-height: 10px;
		align-self: flex-end;
	}
}

.item-menu-list {
	padding: 10px 0;
	height: 100vh;
}

.body {
	width: 100%;

	.list-group {
		padding: 0;
		// border: 1px solid hsv(0, 0, 85%);
		border-radius: 5px;
		width: 100%;

		.list-group-item {
			list-style-type: none;
			display: flex;
			justify-content: flex-start;

			.handle {
				display: inline;
				position: relative;
				left: -4px;
				top: 26px;
				// margin: 0 8px 0 -16px;
				font-size: 16px;
				font-weight: bolder;
				color: rgba(0, 0, 0, 0.35);
				cursor: grab;
				cursor: -moz-grab;
				cursor: -webkit-grab;

				&:active {
					cursor: grabbing;
					cursor: -moz-grabbing;
					cursor: -webkit-grabbing;
				}
			}
		}
	}
}
</style>
