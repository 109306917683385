<template>
	<div>
		<div v-if="isLoading" class="loading-spin">
			<a-spin>
				<a-icon
					slot="indicator"
					type="loading"
					style="font-size: 50px;"
					spin
				/>
			</a-spin>
		</div>
		<div v-else>
			<a-row v-if="sectionsCount === 0">
				<p class="empty-list">
					Nenhuma seção cadastrada até o momento.
				</p>
			</a-row>
			<a-row v-else class="section-menu-list">
				<p class="quotation">
					Lorem ipsum dolor sit amet consectetur adipisicing elit.
					Aperiam reprehenderit omnis labore alias sint suscipit
					distinctio ex voluptatem laboriosam id consequatur sit,
					ullam minus, ab aliquam tempore. Exercitationem, nemo
					asperiores.
				</p>
				<ListBoardSections />
			</a-row>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ListBoardSections from './listBoardSections'

export default {
	name: 'SectionList',
	components: {
		ListBoardSections,
	},
	data() {
		return {
			newCategoryName: '',
			addingCategory: false,
		}
	},
	computed: {
		...mapGetters({
			isLoading: 'menu/isLoading',
			sectionsCount: 'menu/sectionsCount',
		}),
	},
	created() {
		this.fetchSections()
	},
	methods: {
		...mapActions({
			fetchSections: 'menu/fetchSections',
		}),
	},
}
</script>

<style lang="less">
@import url('@/config/globalStyles.less');

.section-menu-list {
	padding: 10px 0;
}
</style>
