<template>
	<a-drawer
		:title="title"
		:visible="showDrawer"
		width="740px"
		:closable="false"
		@close="onClose"
	>
		<a-row>
			<a-alert
				v-if="error != ''"
				type="error"
				:message="error"
				banner
				style="margin-bottom: 15px;"
			/>
		</a-row>
		<a-row>
			<form hideRequiredMark layout="horizontal">
				<a-form-item
					label="Nome"
					:label-col="{ span: 5 }"
					:wrapper-col="{ span: 13 }"
				>
					<a-input
						v-model="subsection.name"
						v-decorator="[
							'name',
							{
								rules: [
									{
										requiered: true,
										message: 'Por favor, Informe o nome!',
									},
								],
							},
						]"
						:auto-focus="true"
						:max-length="60"
						placeholder="Informe o nome da subseção"
					/>
				</a-form-item>
				<a-form-item
					label="Tipo"
					:label-col="{ span: 5 }"
					:wrapper-col="{ span: 16 }"
				>
					<a-input-group compact>
						<a-select v-model="subsection.type">
							<a-select-option value="RDO">
								Selecionável
							</a-select-option>
							<a-select-option value="NUM">
								Contável
							</a-select-option>
						</a-select>
						<a-input-number
							v-model="subsection.min"
							style="width: 60px; text-align: center;"
							placeholder="Mínimo"
							:min="0"
						/>
						<a-input-number
							style="
								width: 35px;
								border-left: 0;
								pointer-events: none;
								background: #fff;
							"
							placeholder="~"
							disabled
						/>
						<a-input-number
							v-model="subsection.max"
							style="
								width: 60px;
								text-align: center;
								border-left: 0;
							"
							placeholder="Máximo"
							:min="minMax"
						/>
					</a-input-group>
					<span class="type-range">(mín ~ máx)</span>
				</a-form-item>
				<a-form-item
					v-if="subsection.max > 1"
					label="Cálculo do preço"
					:label-col="{ span: 5 }"
					:wrapper-col="{ span: 8 }"
					style="margin-top: -24px;"
				>
					<a-select
						v-model="subsection.billing_method"
						default-value="SUM"
					>
						<a-select-option value="SUM">
							Soma
						</a-select-option>
						<a-select-option value="AVG">
							Valor Médio
						</a-select-option>
						<a-select-option value="BGT">
							Maior valor
						</a-select-option>
					</a-select>
				</a-form-item>
			</form>
		</a-row>
		<a-row
			type="flex"
			justify="space-between"
			align="middle"
			class="subitems-header"
		>
			<a-col style="font-weight: bold;"><h3>Subitens</h3></a-col>
			<a-col>
				<a-button
					type="primary"
					class="add-subsection-button"
					icon="plus"
					@click="$refs.subitemList.setShowAdd()"
				>
					Adicionar subitem
				</a-button>
			</a-col>
		</a-row>
		<a-row style="margin-bottom: 40px;">
			<SubitemList
				ref="subitemList"
				:subitems="subitemsList"
				@save="handleSaveSubitem"
				@switch="handleSwitchSubitem"
				@edit="handleEditSubitem"
				@remove="handleRemoveSubitem"
				@sort="handleSortSubitems"
			/>
		</a-row>
		<div v-if="!updating" class="drawer-footer">
			<a-button style="margin-right: 8px;" @click="onClose">
				Cancelar
			</a-button>
			<a-button type="primary" @click="onSave">
				Salvar
			</a-button>
		</div>
		<div v-else class="drawer-footer">
			<a-button type="primary" :loading="loading" @click="onSave">
				Salvar
			</a-button>
		</div>
	</a-drawer>
</template>

<script>
import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import SubitemList from './subitemList'
import { capitalizeFirstLetter, orderByPosition } from '@/utils/utils'

const initialState = {
	name: '',
	min: 0,
	max: 1,
	type: 'RDO',
	is_visible: true,
	billing_method: 'SUM',
	subitems: [],
	position: 0,
}

export default {
	name: 'SubsectionDrawer',
	components: {
		SubitemList,
	},
	props: {
		title: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			showDrawer: false,
			subsection: {},
			error: '',
			updating: false,
			loading: false,
		}
	},
	computed: {
		...mapGetters({
			selectedMerchantId: 'merchants/selectedMerchantId',
		}),
		minMax: function () {
			return !this.subsection.min ? 1 : this.subsection.min
		},
		subitemsList: {
			get: function () {
				return [...this.subsection.subitems].sort(orderByPosition)
			},
			set: function (subitems) {
				this.subsection.subitems = subitems
			},
		},
	},
	watch: {},
	created() {
		this.subsection = { ...initialState }
		this.subsection.subitems = []
	},
	methods: {
		...mapActions('menu', [
			'addSubitem',
			'switchSubitem',
			'removeSubitem',
			'updateSubitem',
			'sortElements',
		]),
		setShowModal(currentValue) {
			if (this.$refs.subitemList) this.$refs.subitemList.clearForms()

			if (currentValue) {
				this.updating = true
				this.subsection = { ...currentValue }
			}
			this.showDrawer = true
		},
		setShowDuplicateModal(currentValue) {
			if (this.$refs.subitemList) this.$refs.subitemList.clearForms()

			this.subsection = _.cloneDeep(currentValue)
			this.subsection.id = ''
			this.subsection.subitems.forEach((subitem) => (subitem.id = ''))
			this.showDrawer = true
		},
		onClose() {
			this.showDrawer = false
			this.clearDrawer()
		},
		onSave() {
			const count = this.subsection.subitems.length

			if (this.subsection.name === '') {
				this.error = 'Por favor, informe o nome da subseção'
			} else if (count < 1 || count < parseInt(this.subsection.min)) {
				const msg =
					count < 1
						? 'Por favor, adicione pelo menos um subitem'
						: 'O número de subitems adicionados é menor que o mínimo informado'
				this.error = msg
			} else {
				if (this.updating) {
					const subsection = {
						name: capitalizeFirstLetter(this.subsection.name),
						is_visible: this.subsection.is_visible,
						max: parseInt(this.subsection.max),
						min: parseInt(this.subsection.min),
						type: this.subsection.type,
						position: this.subsection.position,
						billing_method: this.subsection.billing_method,
					}

					const subsectionId = this.subsection.id

					if (!subsectionId) {
						subsection.subitems = [...this.subsection.subitems]
					}

					this.$emit('update', { subsection, subsectionId })
				} else {
					const subsection = {
						name: capitalizeFirstLetter(this.subsection.name),
						max: parseInt(this.subsection.max),
						min: parseInt(this.subsection.min),
						type: this.subsection.type,
						billing_method: this.subsection.billing_method,
						is_visible: this.subsection.is_visible,
						subitems: [...this.subsection.subitems],
					}
					this.$emit('save', subsection)
				}

				this.showDrawer = false
				this.clearDrawer()
			}
		},
		async handleSaveSubitem(subitem) {
			subitem.position = this.subitemsList.length + 1

			if (this.updating && this.subsection.id) {
				subitem.subsection_id = this.subsection.id

				const payload = {
					merchantId: this.selectedMerchantId,
					itemId: this.subsection.item_id,
					newSubitem: subitem,
				}

				this.loading = true
				await this.addSubitem(payload)
				this.loading = false
			} else {
				this.subitemsList = [...this.subsection.subitems, subitem]
			}
		},
		async handleSwitchSubitem(values) {
			const { value, index } = values

			if (this.updating && this.subsection.id) {
				const payload = {
					merchantId: this.selectedMerchantId,
					itemId: this.subsection.item_id,
					subsectionId: this.subsection.id,
					is_available: value,
					subitemId: this.subsection.subitems[index].id,
					index,
				}

				await this.switchSubitem(payload)
			}

			this.subsection.subitems[index].is_available = value
		},
		async handleRemoveSubitem(index) {
			if (this.updating && this.subsection.id) {
				const payload = {
					merchantId: this.selectedMerchantId,
					itemId: this.subsection.item_id,
					subsectionId: this.subsection.id,
					subitemId: this.subsection.subitems[index].id,
					index,
				}

				this.loading = true
				await this.removeSubitem(payload)
				this.loading = false
			}

			this.subsection.subitems.splice(index, 1)
			await this.handleSortSubitems([...this.subsection.subitems])
		},
		async handleEditSubitem({ updated, index }) {
			if (this.updating && this.subsection.id) {
				const payload = {
					merchantId: this.selectedMerchantId,
					itemId: this.subsection.item_id,
					subsectionId: this.subsection.id,
					subitemId: this.subsection.subitems[index].id,
					data: updated,
					index,
				}

				this.loading = true
				const res = await this.updateSubitem(payload)
				for (const key in res)
					this.subsection.subitems[index][key] = res[key]
				this.subitemsList = this.subsection.subitems
				this.loading = false
			} else {
				for (const key in updated)
					this.subsection.subitems[index][key] = updated[key]
				this.subitemsList = this.subsection.subitems
			}
		},
		handleSortSubitems(subitems) {
			let pos = 0
			const { item_id, id } = this.subsection
			const positions = subitems.map((subitem) => {
				subitem.position = ++pos
				return {
					id: subitem.id,
					position: pos,
				}
			})

			if (this.updating && id) {
				this.sortElements({
					merchantId: this.selectedMerchantId,
					itemId: item_id,
					subsectionId: id,
					elements: 'subitems',
					positions,
				})
			} else {
				this.subitemsList = subitems
			}
		},
		clearDrawer() {
			this.error = ''
			this.updating = false
			this.subsection = { ...initialState }

			this.subitemsList = []

			if (this.$refs.subitemList) this.$refs.subitemList.clearForms()
		},
		updatePositions() {
			let pos = 0
			this.subsection.subitems.forEach(
				(subitem) => (subitem.position = ++pos)
			)
		},
	},
}
</script>

<style lang="less" scoped>
.type-range {
	position: relative;
	color: rgba(0, 0, 0, 0.45);
	top: -40px;
	left: 282px;
}

.subitems-header {
	padding: 7px;
	border-top: 1px solid hsv(0, 0, 85%);
	border-bottom: 1px solid hsv(0, 0, 85%);
}
.drawer-footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	border-top: 1px solid hsv(0, 0, 85%);
	padding: 10px 16px;
	text-align: right;
	left: 0;
	background: #fff;
	border-radius: 0 0 4px 4px;
	margin-top: 25px;
}
</style>
