<template>
	<div>
		<PageHeader :title="$route.meta.title">
			<a-button
				v-if="sectionsCount === 0"
				type="primary"
				@click="setShowModal"
			>
				<a-icon type="plus" />
				Adicionar seção
			</a-button>
		</PageHeader>
		<SectionsCard />
		<SectionModal ref="sectionModal" title="Adicionar seção" />
	</div>
</template>

<script>
import { PageHeader } from '../../components'
import { SectionsCard, SectionModal } from './components'
import { mapGetters, mapActions } from 'vuex'
import mixin from '@/utils/mixinPages'

export default {
	name: 'MenuPage',
	components: {
		PageHeader,
		SectionsCard,
		SectionModal,
	},
	mixins: [mixin],
	data() {
		return {
			showModal: false,
			sectionName: '',
		}
	},
	computed: {
		...mapGetters({
			sectionsCount: 'menu/sectionsCount',
		}),
	},
	methods: {
		...mapActions('menu', ['addSection']),
		setShowModal() {
			this.$refs.sectionModal.setShowModal()
		},
	},
}
</script>
