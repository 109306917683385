<template>
	<div class="item-list-container">
		<a-row
			v-if="sectionSelected"
			type="flex"
			justify="space-between"
			class="item-list-header"
		>
			<a-col :lg="7">
				<span class="section-header">
					{{ sectionSelected.name }}
				</span>
			</a-col>
			<a-col :lg="4" class="section-action-buttons">
				<ActionsButtons
					:is-available="sectionSelected.is_visible"
					description="seção"
					:duplicate="false"
					@switch="handleSwitch"
					@edit="handleEdit"
					@delete="handleDelete"
				/>
			</a-col>
		</a-row>
		<a-row>
			<ItemsList ref="itemsList" />
			<a-row
				type="flex"
				justify="center"
				align="bottom"
				class="footer-add-button"
			>
				<a-button
					type="primary"
					class="add-section-button"
					@click="() => $refs.itemsList.showAddItems()"
				>
					<a-icon type="plus" />Adicionar item
				</a-button>
			</a-row>
		</a-row>
		<SectionModal ref="sectionModal" title="Editar seção" />
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ActionsButtons } from '@/modules/partner/components'
import SectionModal from './sectionModal'
import ItemsList from './itemsList'

export default {
	name: 'SectionItemList',
	components: {
		SectionModal,
		ItemsList,
		ActionsButtons,
	},
	computed: {
		...mapGetters({
			sectionSelected: 'menu/sectionSelected',
			selectedMerchantId: 'merchants/selectedMerchantId',
		}),
		hasItems: function () {
			return this.sectionSelected.items.length > 0
		},
	},
	methods: {
		...mapActions('menu', [
			'editSection',
			'removeSection',
			'switchSection',
			'addSubsection',
			'fetchSections',
		]),
		handleSwitch() {
			this.switchSection({
				merchantId: this.selectedMerchantId,
				sectionId: this.sectionSelected.id,
				is_visible: !this.sectionSelected.is_visible,
			})
		},
		handleEdit() {
			const { name } = this.sectionSelected
			this.$refs.sectionModal.setShowModal(name)
		},
		handleDelete() {
			this.removeSection({
				merchantId: this.selectedMerchantId,
				sectionId: this.sectionSelected.id,
			})
		},
	},
}
</script>

<style lang="less" scoped>
.item-list-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 12px 8px 0 16px;

	.item-list-header {
		border-bottom: 1px solid hsv(0, 0, 85%);
		margin-bottom: 8px;
		padding-bottom: 8px;

		.section-header {
			font-size: 18px;
			font-weight: 500;
			color: rgba(0, 0, 0, 0.85);
		}

		.section-action-buttons {
			margin-right: 8px;
		}
	}

	h4 {
		font-weight: 525;
	}
	.footer-add-button {
		margin: 25px 0;
	}
}
.add-section-button {
	width: 30%;
}
</style>
