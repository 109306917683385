<template>
	<a-modal
		v-model="showModal"
		:title="title"
		centered
		cancel-text="Cancelar"
		ok-text="Salvar"
		style="width: 100%;"
		@ok="handleSubmit"
		@cancel="cancelModal"
	>
		<form hideRequiredMark>
			<a-form-item
				label="Nome da Seção: "
				:label-col="{ span: 7 }"
				:wrapper-col="{ span: 15 }"
			>
				<a-input
					v-model="sectionName"
					v-decorator="['section']"
					:auto-focus="true"
					@keyup.enter="handleSubmit"
				/>
			</a-form-item>
		</form>
	</a-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'SectionModal',
	props: {
		title: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			showModal: false,
			initialValue: '',
			sectionName: '',
		}
	},
	computed: {
		...mapGetters({
			sectionSelected: 'menu/sectionSelected',
			sectionsCount: 'menu/sectionsCount',
			selectedMerchantId: 'merchants/selectedMerchantId',
		}),
	},
	methods: {
		...mapActions('menu', [
			'addSection',
			'setCurrentSection',
			'editSection',
		]),
		setShowModal(currentValue = '') {
			this.initialValue = currentValue
			this.sectionName = currentValue
			this.showModal = true
		},
		cancelModal() {
			this.initialValue = ''
			this.sectionName = ''
		},
		handleSubmit() {
			const { sectionName, initialValue } = this

			if (sectionName) {
				if (initialValue === '') {
					const newSection = {
						name: this.sectionName,
						position: this.sectionsCount + 1,
						is_visible: false,
					}

					this.addSection({
						merchantId: this.selectedMerchantId,
						newSection,
					})
				} else {
					this.editSection({
						merchantId: this.selectedMerchantId,
						sectionId: this.sectionSelected.id,
						data: {
							name: this.sectionName,
						},
					})
				}
				this.showModal = false
			}
		},
	},
}
</script>
